import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "HTTP コンテンツ圧縮でパフォーマンス改善",
  "date": "2021-02-01T09:00:03.000Z",
  "slug": "entry/2021/02/01/180003",
  "tags": ["medley"],
  "hero": "./2021_02_01.png",
  "heroAlt": "コンテンツ圧縮"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`事業本部 プロダクト開発室のエンジニアの中畑です。`}</p>
    <p>{`オンライン診療・服薬指導・クラウド診療支援システム`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`「CLINICS」`}</a>{`の開発・基盤周りを担当しております。`}</p>
    <p>{`今回は、HTTP のコンテンツ圧縮について調査・対応する機会があったので、本ブログにて紹介したいと思います。`}</p>
    <h1>{`HTTP コンテンツの圧縮とは`}</h1>
    <p>{`HTTP コンテンツの圧縮とは、HTTP の通信において Web サーバー側が返すデータを、なんらかの形式で圧縮してクライアントに返すことです。圧縮されたレスポンスをクライアント側は解凍して利用します。`}</p>
    <p>{`HTTP コンテンツの圧縮によって得られるメリット・デメリットは以下の通りです。`}</p>
    <h2>{`⤴ メリット`}</h2>
    <ul>
      <li parentName="ul">{`通信の帯域使用量を減らせる`}</li>
      <li parentName="ul">{`それによって通信にかかる時間を削減し、`}<strong parentName="li">{`ページ表示速度を向上`}</strong>{`できる`}</li>
    </ul>
    <h2>{`⤵ デメリット`}</h2>
    <ul>
      <li parentName="ul">{`圧縮・解凍コストがかかる`}
        <ul parentName="li">
          <li parentName="ul">{`ただし、圧縮・解凍コストはほとんどの場合は小さいため、メリットを下回る`}</li>
        </ul>
      </li>
      <li parentName="ul">{`大容量ファイルやもともと圧縮されているファイル（画像や動画、PDF ファイルなど）を圧縮するのは、圧縮してもサイズがそれほど小さくならないため非効率である`}
        <ul parentName="li">
          <li parentName="ul">{`サイズがあまり削減できない割に、圧縮・解凍に CPU リソースを使い、数百 MB を超えるファイルになるとそれぞれ数秒かかることもある`}</li>
        </ul>
      </li>
    </ul>
    <h1>{`HTTP コンテンツを圧縮するためには`}</h1>
    <p>{`HTTP コンテンツを圧縮するためには、クライアントが解凍可能な圧縮形式を指定する必要があります。解凍可能な圧縮形式を指定するには、リクエストヘッダに`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Accept-Encoding`}</code>{`ヘッダを指定します。`}</p>
    <p>{`最近のブラウザでは、HTTP リクエスト時に自動的に`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Accept-Encoding`}</code>{`ヘッダを自動的に付加してアクセスしているので、ブラウザ経由の場合は特に明示的に指定する必要はありません。Chrome, Safari, Edge など、ほとんどのメジャーなブラウザでは`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Accept-Encoding: gzip, deflate, br`}</code>{`が指定されています(※2021-01-23 時点)。`}</p>
    <h2>{`圧縮形式(gzip, deflate, br)`}</h2>
    <p>{`圧縮形式はいくつかありますが、ブラウザを利用する場合は以下のいずれかが選択肢になります。`}</p>
    <ul>
      <li parentName="ul">{`gzip: LZ77 と 32 ビット CR を用いた圧縮形式`}</li>
      <li parentName="ul">{`deflate: zlib 構造体と deflate 圧縮アルゴリズムを用いた圧縮形式`}</li>
      <li parentName="ul">{`br: Brotli アルゴリズムを用いた圧縮形式。gzip に近いが大容量の言語辞書を用いて、頻出するパターンの単語を圧縮して効率化。そのため文章的なテキストでは`}<strong parentName="li">{`gzip よりも圧縮率が高い`}</strong>{`と言われる`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://github.com/google/brotli"
      }}>{`Brotli`}</a>{`は比較的新しい形式で、ほとんどのサーバー、ブラウザで対応しています。`}</p>
    <h1>{`サーバーでの HTTP コンテンツの圧縮方法(gzip)`}</h1>
    <p>{`サーバーはクライアントの`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Accept-Encoding`}</code>{`リクエストヘッダを受け取り、その中から 1 つを選択して圧縮処理を行い、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Content-Encoding`}</code>{`レスポンスヘッダを付加してクライアントに結果を知らせます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210129/20210129123916.png",
      "alt": "20210129123916.png"
    }}></img>
    <p>{`CLINICS が利用しているそれぞれのアプリケーション・ミドルウェアに絞って、どのように HTTP コンテンツ圧縮を実現しているか解説したいと思います。いくつか圧縮形式はありますが、ここでは gzip 形式での圧縮方法について解説します。`}</p>
    <h2>{`NGINX`}</h2>
    <p>{`NGINX の`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ngx_http_gzip_module`}</code>{`を利用することで gzip 圧縮することができます。`}</p>
    <p>{`nginx.conf の`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gzip`}</code>{`ディレクティブを`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`on`}</code>{`にすることで圧縮が有効になります。ただし、タイプを指定しないと`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Content-Type: text/html`}</code>{`のときにしか圧縮されません。他のタイプでも圧縮したいときは`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gzip_types`}</code>{`ディレクティブも合わせて指定する必要があります。`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gzip_types`}</code>{`に`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`*`}</code>{`を指定することで、すべてのコンテンツを圧縮することもできます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "nginx"
    }}><pre parentName="div" {...{
        "className": "language-nginx"
      }}><code parentName="pre" {...{
          "className": "language-nginx"
        }}><span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`gzip:`}</span>{` `}<span parentName="span" {...{
              "className": "token boolean"
            }}>{`on`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
gzip_types: text/css application/javascript application/json`}</code></pre></div>
    <p>{`また、CloudFront など Proxy を経由してのアクセスの場合はデフォルトでは行われません。Proxy 経由のアクセスかどうかは、リクエストヘッダに`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Via`}</code>{`ヘッダがあるかどうかで判定します。`}</p>
    <p>{`CloudFront 経由でのアクセスの場合は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Via: 1.1 xxxxx.cloudfront.net (CloudFront)`}</code>{`のように`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Via`}</code>{`ヘッダが付加されているため、NGINX にて Proxy 経由であると判定します。Proxy 経由であっても何かしらの条件で圧縮したい場合は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gzip_proxied`}</code>{`ディレクティブを指定する必要があります。`}</p>
    <p>{`ref. `}<a parentName="p" {...{
        "href": "https://nginx.org/en/docs/http/ngx_http_gzip_module.html"
      }}>{`https://nginx.org/en/docs/http/ngx_http_gzip_module.html`}</a></p>
    <h2>{`CloudFront`}</h2>
    <p>{`CloudFront の Behavior の設定にて設定します。Compress Objects Automatically を有効化することで、gzip 圧縮が有効になります。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210129/20210129124017.png",
      "alt": "20210129124017.png"
    }}></img>
    <p>{`上記を有効化すると、CloudFront では以下の条件で圧縮が行われます。`}</p>
    <ul>
      <li parentName="ul">{`ファイルサイズが 1,000(≒1KB) 〜 10,000,000(≒10MB) バイトの間`}
        <ul parentName="li">
          <li parentName="ul">{`よって、オリジンからファイルサイズを判定するための`}<code parentName="li" {...{
              "className": "language-text"
            }}>{`Content-Length`}</code>{`ヘッダが付与されていない場合は、サイズ判別できないため圧縮されない`}</li>
        </ul>
      </li>
      <li parentName="ul">{`特定の`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Content-Type`}</code>{`のコンテンツを圧縮する`}
        <ul parentName="li">
          <li parentName="ul">{`テキスト系のコンテンツは圧縮するが、画像や動画、PDF など、もともと圧縮されているものは対象外。詳しくは`}<a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/ja_jp/AmazonCloudFront/latest/DeveloperGuide/ServingCompressedFiles.html#compressed-content-cloudfront-file-types"
            }}>{`こちら`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`オリジン側（NGINX や Rails など）から圧縮して返される場合は、`}<strong parentName="li">{`再度圧縮は行わない`}</strong>
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`Content-Encoding`}</code>{`ヘッダの有無で判定している`}</li>
        </ul>
      </li>
    </ul>
    <p>{`ref. `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/ja_jp/AmazonCloudFront/latest/DeveloperGuide/ServingCompressedFiles.html"
      }}>{`https://docs.aws.amazon.com/ja_jp/AmazonCloudFront/latest/DeveloperGuide/ServingCompressedFiles.html`}</a></p>
    <h2>{`Rails`}</h2>
    <p>{`Rails はデフォルトでは HTTP コンテンツの圧縮は行いません。Rails でコンテンツ圧縮を行いたい場合は、Rails の Rack Middleware の`}<a parentName="p" {...{
        "href": "https://github.com/rack/rack/blob/master/lib/rack/deflater.rb"
      }}>{`Rack::Deflater`}</a>{`を導入するのが簡単です。しかしながら、Rack::Deflater はすべての`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Content-Type`}</code>{`のコンテンツでも圧縮するので、画像や動画・ PDF など圧縮するべきでないコンテンツまで圧縮してしまいます。NGINX や CloudFront など、Rails 外の他のサービスやミドルウェアに任せるのが良いと思います。`}</p>
    <h1>{`CLINICS での HTTP コンテンツ圧縮のシーケンス`}</h1>
    <p>{`前章で解説したアプリケーション・ミドルウェアは、CLINICS では以下のように連携しています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210129/20210129132442.png",
      "alt": "20210129132442.png"
    }}></img>
    <p>{`AWS 上に Rails アプリケーションをデプロイしており、通常のアクセスはロードバランサーから NGINX を経由して Rails にアクセスし、静的ファイルなどキャッシュコンテンツは CloudFront 経由でアクセスしています。`}</p>
    <p>{`CLINICS では用途に合わせた圧縮を行っています。3 つのケースを紹介します。`}</p>
    <h2>{`1. NGINX 経由で Rails にアクセスした時`}</h2>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210129/20210129124126.png",
      "alt": "20210129124126.png"
    }}></img>
    <p>{`API アクセスなどは上記シーケンスでアクセスしています。ほとんどが`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`text/html`}</code>{`や`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`application/json`}</code>{`形式のコンテンツとなり、NGINX にて gzip 圧縮処理を行っています。Rails はアプリケーションの処理のみを行い、圧縮は行わないようにしています。`}</p>
    <h2>{`2. CloudFront 経由で S3 にアクセスした時`}</h2>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210129/20210129124148.png",
      "alt": "20210129124148.png"
    }}></img>
    <p>{`画像ファイルや PDF、静的な js、css ファイルなどはサービスのデプロイ時に S3 にアップロードしています。クライアントは CloudFront 経由でアクセスし、S3 から取得して、CloudFront で gzip に圧縮処理を行っています。また、一定期間 CloudFront 上にキャッシュされるので、効率よく圧縮コンテンツを返します。`}</p>
    <h2>{`3. CloudFront→NGINX→Rails 経由で S3 にアクセスした時`}</h2>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210129/20210129124210.png",
      "alt": "20210129124210.png"
    }}></img>
    <p>{`静的ファイルの中でもシグネチャをチェックしているものは、このフローでアクセスしています。NGINX でも圧縮設定を ON にしていますが、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Via`}</code>{`ヘッダがあるため、NGINX では圧縮しないようになっています。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`HTTP コンテンツの圧縮を適切に行うことで、サービス全体のパフォーマンス向上が見込めます。更に CloudFront を活用することで、アプリケーションやミドルウェアでの圧縮処理をなくし、更なるパフォーマンス向上が見込めます。`}</p>
    <p>{`今回は HTTP コンテンツの gzip 圧縮についてのみ触れましたが、Brotli 圧縮についても NGINX、CloudFront ともに可能なため、今後取り入れていきたいと考えています。もし HTTP コンテンツの圧縮設定を特に気にしたことがない方は一度確認してみてはいかがでしょうか？`}</p>
    <h1>{`最後に`}</h1>
    <p>{`メドレーでは、医療分野の社会課題を IT にて解決するために日々邁進しています。医療という分野においては、機微な情報を扱ったり診療を止めないようにするために、パフォーマンス・セキュリティ共に高いサービスレベルが求められます。興味を持った方がいらっしゃいましたら、まずは気軽に面談できればと思いますので、是非ご応募ください！`}</p>
    <p>{`最後までお読みいただきありがとうございました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      